import React from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  PageHeader,
  Badge,
  Table,
  Input,
  Modal,
  Select,
  Spin,
  Divider,
  Card,
  Typography,
  Tabs,
  AutoComplete,
  Upload,
  Checkbox,
  Space,
  InputNumber,
  Switch,
  Tooltip,
} from "antd";
import {
  CloseCircleFilled,
  EditFilled,
  CheckOutlined,
  CloseOutlined,
  BellOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DeleteFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { ButtonSquare } from "src/components/elements/Button";
import dropImage from "src/static/icons/carImageUpload/placeholder.png";
import Resizer from "react-image-file-resizer";
import { Form } from "@ant-design/compatible";
import Notify from "src/components/meta/Notification";
import Highlighter from "react-highlight-words";
import "braft-editor/dist/index.css";
import BraftEditor from "braft-editor";
import Editor from "react-quill-antd";
import "react-quill-antd/dist/index.css";

const { Title } = Typography;
const { Column } = Table;
const FormItem = Form.Item;

const { TabPane } = Tabs;

const { Option } = Select;
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const StyledTable = styled(Table)`
  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    padding: 10px 8px !important;
  }
  .ant-table {
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.43) !important;
    border: 1px solid grey !important;
    border-top-left-radius: 12px !important;
    border-bottom: 0px solid grey !important;
    border-top-right-radius: 12px !important;
    background: white !important;
  }
  .ant-table-thead > tr > th {
    color: white !important;
    background: rgb(66, 148, 211) !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid grey !important;
    transition: background 0.3s;
    font-weight: 500;
    color: #525f7f;
    font-size: 15px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: "";
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 1.5em !important;
    width: 1.5em !important;
  }
`;
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 47px !important;
  border-right-width: 0px !important;
    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border-bottom: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;
    border-right-width: 0px !important;
    box-shadow:none !important;
}
  :active{
    border-color: red !important;
    border-right-width: 0px !important;
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    border-right-width: 0px !important;
     box-shadow:none !important;
   
  }
  }
`;
const StyledInputNumber = styled(InputNumber)`
  font-weight: 500 !important;
  color: #525f7f !important;
  font-size: 15px !important;
  cursor: not-allowed;
  .ant-input-number-disabled .ant-input-number-input {
    font-weight: 500 !important;
    color: #525f7f !important;
    font-size: 15px !important;
    cursor: not-allowed;
  }
`;
const StyledSelect = styled(Select)`

.ant-select-arrow {

  color: white !important;

}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid grey !important;
  border-radius: 0px !important;
  height: 47px !important;
  box-shadow: none !important;
  :hover {
    border-color: grey !important;
    border-radius: 0px !important;
    box-shadow: none !important;
  }
  :active {
    border-color: grey !impo  border-radius: 0px !important;rtant;

    box-shadow: none !important;
  }
  :focus {
    border-color: grey !important;
    border-radius: 0px !important;
    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: grey !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
function callback(key) {
  console.log(key);
}
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class AddStockForm extends React.Component {
  state = {
    manualInput: this.props.editStock
      ? this.props.editStock.manualInput
      : false,
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
    stockNumbers: [],
    years: [],
    makes: [],
    variants: [],
    models: [],
    mileages: [],
    retailIncs: [],
    intColours: [],
    extColours: [],
    capacityCCs: [],
    bodyTypes: [],
    engineSizes: [],
    unladenWeights: [],
    vehicleDrives: [],
    axles: [],
    doors: [],
    cylinders: [],
    passengers: [],
    vehicleStockNumbers: [],
    vehicleStockNumber: undefined,
    intColour: undefined,
    extColour: undefined,
    passenger: undefined,
    retailPriceIncl: undefined,
    cylinder: undefined,
    unladenWeight: undefined,
    axle: undefined,
    vehicleDrive: undefined,
    engineSize: undefined,
    bodyType: undefined,
    capacityCC: undefined,
    stockNumber: undefined,
    modelYear: undefined,
    make: undefined,
    mileage: undefined,
    series: undefined,
    branchImage: undefined,
    frontLeftImage: undefined,
    frontImage: undefined,
    frontRightImage: undefined,
    rightSideImage: undefined,
    wheelsImage: undefined,
    backRightImage: undefined,
    backImage: undefined,
    backLeftImage: undefined,
    leftSideImage: undefined,
    bootImage: undefined,
    frontSeatImage: undefined,
    backSeatImage: undefined,
    dashPassengerImage: undefined,
    dashDriverImage: undefined,
    dashDriverSideImage: undefined,
    radioImage: undefined,
    radioCloseImage: undefined,
    gearsImage: undefined,
    steeringImage: undefined,
    spareKeyImage: undefined,
    disabled: true,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };

  componentWillMount = () => {
    if (this.props.editStock) {
      this.setState({
        branchImage: this.props.editStock.branchImage,
        frontLeftImage: this.props.editStock.frontLeftImage,
        frontImage: this.props.editStock.frontImage,
        frontRightImage: this.props.editStock.frontRightImage,
        rightSideImage: this.props.editStock.rightSideImage,
        wheelsImage: this.props.editStock.wheelsImage,
        backRightImage: this.props.editStock.backRightImage,
        backImage: this.props.editStock.backImage,
        backLeftImage: this.props.editStock.backLeftImage,
        leftSideImage: this.props.editStock.leftSideImage,
        bootImage: this.props.editStock.bootImage,
        frontSeatImage: this.props.editStock.frontSeatImage,
        backSeatImage: this.props.editStock.backSeatImage,
        dashPassengerImage: this.props.editStock.dashPassengerImage,
        dashDriverImage: this.props.editStock.dashDriverImage,
        dashDriverSideImage: this.props.editStock.dashDriverSideImage,
        radioImage: this.props.editStock.radioImage,
        radioCloseImage: this.props.editStock.radioCloseImage,
        gearsImage: this.props.editStock.gearsImage,
        steeringImage: this.props.editStock.steeringImage,
        spareKeyImage: this.props.editStock.spareKeyImage,
      });
    }
  };
  handleBranchImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImage: imageUrl,
      });
    });
  };
  handleFrontLeftImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontLeftImage: imageUrl,
      });
    });
  };
  handleFrontImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontImage: imageUrl,
      });
    });
  };
  handleFrontRightImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontRightImage: imageUrl,
      });
    });
  };
  handleRightSideImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        rightSideImage: imageUrl,
      });
    });
  };
  handleRadioCloseImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        radioCloseImage: imageUrl,
      });
    });
  };
  handleWheelsImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        wheelsImage: imageUrl,
      });
    });
  };
  handleBackRightImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backRightImage: imageUrl,
      });
    });
  };
  handleBackImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backImage: imageUrl,
      });
    });
  };
  handleBackLeftImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backLeftImage: imageUrl,
      });
    });
  };
  handleLeftSideImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        leftSideImage: imageUrl,
      });
    });
  };
  handleBootImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        bootImage: imageUrl,
      });
    });
  };
  handleFrontSeatImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontSeatImage: imageUrl,
      });
    });
  };
  handleBackSeatImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backSeatImage: imageUrl,
      });
    });
  };
  handleDashPassengerImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        dashPassengerImage: imageUrl,
      });
    });
  };
  handleDashDriverImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        dashDriverImage: imageUrl,
      });
    });
  };
  handleDashDriverSideImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        dashDriverSideImage: imageUrl,
      });
    });
  };
  handleRadioImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        radioImage: imageUrl,
      });
    });
  };
  handleRadioCloseImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        radioCloseImage: imageUrl,
      });
    });
  };
  handleGearsImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        gearsImage: imageUrl,
      });
    });
  };
  handleSteeringImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        steeringImage: imageUrl,
      });
    });
  };
  handleSpareKeyImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        spareKeyImage: imageUrl,
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 800, 800, "JPG", 100, 0, uri => {
      callback(uri);
    });
  };

  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Form layout="vertical">
        <Row>
          <Col span={24} style={{ display: "none" }}>
            <FormItem label={<span>Workout Plan</span>}>
              {this.props.form.getFieldDecorator("condition", {
                // normalize: input => input.toUpperCase(),
                rules: [
                  {
                    required: true,
                    message: "Status is required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.condition
                  : "",
              })(
                <StyledSelect>
                  <Option key={"CONTACT LEAD"} value={"CONTACT LEAD"}>
                    CONTACT LEAD
                  </Option>
                  <Option key={"TRANSFORMATION PROGRAM"} value={"TRANSFORMATION PROGRAM"}>
                    TRANSFORMATION PROGRAM
                  </Option>
                  <Option key={"12 WEEK PLAN"} value={"12 WEEK PLAN"}>
                    12 WEEK PLAN
                  </Option>
                  <Option key={"PREMIUM PLAN"} value={"PREMIUM PLAN"}>
                    PREMIUM PLAN
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>

          <Col span={4} style={{ margin: "auto" }}>
            <h3 style={{ color: "white" }}>Paid</h3>
            <FormItem>
              {this.props.form.getFieldDecorator("paid", {
                // normalize: input => input.toUpperCase(),
                rules: [
                  {
                    required: true,
                    message: "Status is required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.paid
                  : "No",
              })(
                <StyledSelect>
                  <Option key={"Yes"} value={"Yes"}>
                    Yes
                  </Option>
                  <Option key={"No"} value={"No"}>
                    No
                  </Option>
                </StyledSelect>
              )}
            </FormItem>
          </Col>
          {/* <Col span={24} style={{ display: "none" }}>
            <FormItem label={<span>Date</span>}>
              {this.props.form.getFieldDecorator("timeDate", {
                rules: [{}],
                initialValue: this.state.curDT,
              })(
                <StyledInput
                  value={this.state.curDT}
                  defaultValue={this.state.curDT}
                />
              )}
            </FormItem>
          </Col> */}
        </Row>
        <Row>
          {/* <Col span={6} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                position: "absolute",
                top: "-8em",
                width: '100%',
                border: '1px solid black',
                marginBottom: 15
              }}
              extra={
                this.state.frontImage ? (
                  <div onClick={e => this.setState({ frontImage: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: 'black' }}>Video Placeholder</span>}
              cover={
                this.state.frontImage ? (
                  <img
                    alt="example"
                    src={
                      this.state.frontImage.search('data') > -1
                        ? this.state.frontImage
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'bactive/api/images/' +
                        this.state.frontImage
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="frontImage"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleFrontImage}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <Button>Upload Image</Button>
                </Upload>
              ]}
            />
          </Col> */}

          <Col span={24}>
            <h3 style={{ color: "white" }}>Name & Surname</h3>
            <FormItem>
              {this.props.form.getFieldDecorator("eventTitle", {
                rules: [
                  {
                    required: true,
                    message: "Name & Surname required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.eventTitle
                  : "",
              })(
                <h1 style={{ color: "white" }}>
                  {this.props.editStock.eventTitle}
                </h1>
              )}
            </FormItem>
          </Col>
          <Col span={24}>
            <h3 style={{ color: "white" }}>Contact Number</h3>
            <FormItem>
              {this.props.form.getFieldDecorator("contactNumber", {
                rules: [
                  {
                    required: false,
                    message: "Contact Number required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.contactNumber
                  : "",
              })(
                <h1 style={{ color: "white" }}>
                  {this.props.editStock.contactNumber}
                </h1>
              )}
            </FormItem>
          </Col>
          {/* <Col span={2} />
          <Col span={8}>
            <FormItem label="Webinar or 12 WEEK PLAN Location">
              {this.props.form.getFieldDecorator('leadEmail', {
                rules: [
                  {
                    required: true,
                    message: 'Webinar location required'
                  }
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.leadEmail
                  : ''
              })(
                <StyledInput size="large" placeholder="please enter a title" />
              )}
            </FormItem>
          </Col> */}
          {/* {this.props.editStock ? null : (
            <Button
              type="primary"
              onClick={this.toggleDisabled}
              style={{ marginBottom: '1em' }}
            >
              Use Generic Competition Template
            </Button>
          )} */}

          <Col span={24}>
            <h3 style={{ color: "white" }}>Email</h3>
            <FormItem>
              {this.props.form.getFieldDecorator("leadEmail", {
                rules: [
                  {
                    required: true,
                    message: "Email required",
                  },
                ],

                initialValue: this.props.editStock
                  ? this.props.editStock.leadEmail
                  : "",
              })(
                <h1 style={{ color: "white" }}>
                  {this.props.editStock.leadEmail}
                </h1>
              )}
            </FormItem>
          </Col>
          {/* <Col
            span={5}
            style={{ position: 'fixed', bottom: '8.8em', right: '1.5em' }}
          >
            <FormItem label="  ">
              {this.props.form.getFieldDecorator('manualInput', {
                rules: [
                  {
                    required: false
                  }
                ]
              })(
                <Tooltip placement="left" title="Manual Input">
                  <Switch
                    onChange={this.selectManual}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  //defaultChecked={
                  //  this.props.editStock
                  //    ? this.props.editStock.manualInput === true
                  //      ? true
                  //      : false
                  //    : false
                  //}
                  />
                </Tooltip>
              )}
            </FormItem>
          </Col>{' '} */}
          {/* <Col span={6}>
            <FormItem label="  ">
              {this.props.form.getFieldDecorator('webVisible', {
                rules: [
                  {
                    required: false
                  }
                ],
                valuePropName: 'checked',
                initialValue: this.props.editStock
                  ? this.props.editStock.webVisible
                    ? true
                    : false
                  : true
              })(
                <Checkbox style={{ marginTop: '2em' }}>
                  Vehicle Visible on Website
                </Checkbox>
              )}
            </FormItem>
          </Col>{' '}
          <Col span={12}>
            <FormItem label="  ">
              {this.props.form.getFieldDecorator('homeVisible', {
                rules: [
                  {
                    required: false
                  }
                ],
                valuePropName: 'checked',
                initialValue: this.props.editStock
                  ? this.props.editStock.homeVisible
                    ? true
                    : false
                  : false
              })(
                <Checkbox style={{ marginTop: '2em' }}>
                  Display Vehicle on Home Page
                </Checkbox>
              )}
            </FormItem>
          </Col> */}
        </Row>{" "}
        {/* <h1
          style={{
            marginTop: '1.5em',
            marginBottom: '1.5em',
            fontSize: '17px',
            fontWeight: 700
          }}
        >
          Image Uploads
        </h1> */}
        <Row type="flex" justify="space-between" align="middle">
          {/* <Col span={6} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid black',
                marginBottom: 10
              }}
              extra={
                this.state.frontLeftImage ? (
                  <div onClick={e => this.setState({ frontLeftImage: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: 'black' }}>Image</span>}
              cover={
                this.state.frontLeftImage ? (
                  <img
                    alt="example"
                    src={
                      this.state.frontLeftImage.search('data') > -1
                        ? this.state.frontLeftImage
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'bactive/api/images/' +
                        this.state.frontLeftImage
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="frontLeftImage"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleFrontLeftImage}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <ButtonSquare>Upload Image</ButtonSquare>
                </Upload>
              ]}
            />
          </Col> */}
          {/* <Col span={6} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid black',
                marginBottom: 15
              }}
              extra={
                this.state.frontImage ? (
                  <div onClick={e => this.setState({ frontImage: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: 'black' }}>Video Placeholder</span>}
              cover={
                this.state.frontImage ? (
                  <img
                    alt="example"
                    src={
                      this.state.frontImage.search('data') > -1
                        ? this.state.frontImage
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'bactive/api/images/' +
                        this.state.frontImage
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="frontImage"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleFrontImage}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <Button>Upload Image</Button>
                </Upload>
              ]}
            />
          </Col> */}
          {/* <Col span={6} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                border: '1px solid black',
                marginBottom: 15
              }}
              extra={
                this.state.frontRightImage ? (
                  <div onClick={e => this.setState({ frontRightImage: null })}>
                    <CloseCircleFilled style={{ color: 'red' }} />
                    <span style={{ color: 'red' }}>Remove</span>
                  </div>
                ) : (
                  ''
                )
              }
              title={<span style={{ color: 'black' }}> Image</span>}
              cover={
                this.state.frontRightImage ? (
                  <img
                    alt="example"
                    src={
                      this.state.frontRightImage.search('data') > -1
                        ? this.state.frontRightImage
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'bactive/api/images/' +
                        this.state.frontRightImage
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
              actions={[
                <Upload
                  name="frontRightImage"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleFrontRightImage}
                  loading={this.state.loading}
                  accept="image/*"
                >
                  <Button>Upload Image</Button>
                </Upload>
              ]}
            />
          </Col> */}
        </Row>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>{" "}
        {/*<Row type='flex' justify='space-around' align='middle'>
          <Button type='primary' onClick={this.props.onOk}>
            {this.props.okText}
          </Button>
          <Button type='primary' onClick={this.props.onCancel}>
            Cancel
          </Button>
        </Row>*/}
      </Form>
    );
  }
}
const StockForm = Form.create()(AddStockForm);

class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editStock: undefined,
      size: "large",
      flag: false,
      currentTab: "All",
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllStock()
      .then(() => {
        let itemCodes = [];
        this.props.stock.forEach(stock => {
          if (stock.itemCode) {
            itemCodes.push(
              "Part: " + stock.itemCode + " - Location: " + stock.location
            );
          }
        });
        this.setState({ loading: false, itemCodes: itemCodes });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllStock()
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      },
    };
    window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };
  handleBack = () => {
    if (this.state.previewVisible) {
      this.setState({ previewVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  vehiclesForm;
  onAddStock = e => {
    this.setState({ previewVisible: true, editStock: undefined });
  };

  handleAddStock = e => {
    e.preventDefault();
    this.vehiclesForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.branchImage = this.vehiclesForm.state.branchImage;
        values.frontLeftImage = this.vehiclesForm.state.frontLeftImage;
        values.frontImage = this.vehiclesForm.state.frontImage;
        values.frontRightImage = this.vehiclesForm.state.frontRightImage;
        values.rightSideImage = this.vehiclesForm.state.rightSideImage;
        values.wheelsImage = this.vehiclesForm.state.wheelsImage;
        values.backRightImage = this.vehiclesForm.state.backRightImage;
        values.backImage = this.vehiclesForm.state.backImage;
        values.backLeftImage = this.vehiclesForm.state.backLeftImage;
        values.leftSideImage = this.vehiclesForm.state.leftSideImage;
        values.bootImage = this.vehiclesForm.state.bootImage;
        values.frontSeatImage = this.vehiclesForm.state.frontSeatImage;
        values.backSeatImage = this.vehiclesForm.state.backSeatImage;
        values.dashPassengerImage = this.vehiclesForm.state.dashPassengerImage;
        values.dashDriverImage = this.vehiclesForm.state.dashDriverImage;
        values.dashDriverSideImage = this.vehiclesForm.state.dashDriverSideImage;
        values.radioImage = this.vehiclesForm.state.radioImage;
        values.radioCloseImage = this.vehiclesForm.state.radioCloseImage;
        values.gearsImage = this.vehiclesForm.state.gearsImage;
        values.steeringImage = this.vehiclesForm.state.steeringImage;
        values.spareKeyImage = this.vehiclesForm.state.spareKeyImage;
        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock()
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved lead");
                } else {
                  Notify("success", "Successfully added lead");
                }
                this.vehiclesForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  previewVisible: false,
                  editStock: undefined,
                  currentTab: "All",
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  handleEditStock = e => {
    this.setState({ previewVisible: true, editStock: e });
  };

  handleDeleteStock = e => {
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are you sure you want to delete this lead?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteStock(id)
          .then(() => {
            that.props
              .findAllStock()
              .then(() => {
                that.setState({
                  loading: false,
                  currentTab: "All",
                  previewVisible: false,
                });
                // that.props.find140mm();
                // that.props.find171mm();
                Notify("success", "Successfully deleted lead");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      sortedInfo: sorter,
    });
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let stock = this.state.stock;

    stock.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "black" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });

  renderCondition = (condition, color) => {
    return (
      <p style={{ margin: 0 }}>
        {/* <StyledBadge color={color} style={{}} /> */}
        {condition}
      </p>
    );
  };

  changeTypeTab = key => {
    this.setState({ currentTab: key });
  };
  formatDate = (text) => {
    const momentObj = moment(text, "MM/DD/YYYY, HH:mm:ss");
    if (momentObj.isValid()) {
      return momentObj.format("DD/MM/YYYY, HH:mm:ss");
    } else {
      return text;
    }
  };
  render() {
    let descriptions = this.props.stock.map(vehicle => {
      return { value: vehicle.description };
    });
    const { condition } = this.state;
    const { stock } = this.props;
    let vehicles;

    if (this.state.currentTab === "All") {
      vehicles = stock;
    } else {
      vehicles = stock.filter(
        vehicle => vehicle.condition === this.state.currentTab
      );
    }
    const dataSource = [
      {
        key: "1",
        name: "Mike",
        age: 32,
        address: "10 Downing Street",
      },
      {
        key: "2",
        name: "John",
        age: 42,
        address: "10 Downing Street",
      },
    ];

    const columns = [
      {
        title: "Year",
        dataIndex: "modelYear",
        key: "modelYear",
      },
      {
        title: "Make",
        dataIndex: "make",
        key: "make",
      },
      {
        title: "Model",
        dataIndex: "series",
        key: "series",
      },
      {
        title: "Derivative",
        dataIndex: "modelDesc",
        key: "modelDesc",
      },
      {
        title: "Condition",
        dataIndex: "condition",
        //key: "condition",
        render: condition => {
          switch (condition) {
            case "CONTACT LEAD":
              return this.renderCondition("CONTACT LEAD", "#4BED5B");
            case "TRANSFORMATION PROGRAM":
              return this.renderCondition("TRANSFORMATION PROGRAM", "lightgrey");
            case "12 WEEK PLAN":
              return this.renderCondition("12 WEEK PLAN", "blue");
            case "PREMIUM PLAN":
              return this.renderCondition("PREMIUM PLAN", "blue");
            default:
              return this.renderCondition("None", "lightgrey");
          }
        },
      },
      {
        title: "Price",
        dataIndex: "price",
        //key: "price",
        render: price => (
          <StyledInputNumber
            bordered={false}
            defaultValue={price}
            disabled
            formatter={price =>
              `R ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={price => price.replace(/\R\s?|(,*)/g, "")}
          />
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (text, stock) => (
          <span>
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "3em",
              }}
              onClick={e => this.handleEditStock(stock)}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <DeleteFilled
              style={{
                backgroundColor: "#CFD5E3",
                color: "white",
                padding: "0.5em",
                borderRadius: "3em",
              }}
              data={stock._id}
              onClick={this.handleDeleteStock}
            />
          </span>
        ),
      },
    ];
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Spin spinning={this.state.loading}>
          {this.state.previewVisible ? (
            <Card
              bordered={false}
              extra={
                <Title
                  style={{
                    fontSize: "29px",
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    color: "white",
                  }}
                >
                  {this.state.editStock
                    ? this.state.editStock.condition
                    : "Add Status"}
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "15px",
                      whiteSpace: "nowrap",
                      marginTop: "0.5em",
                      marginBottom: "0em",
                      color: "white",
                    }}
                  >
                    {this.state.editStock
                      ? "Please click save to edit lead"
                      : "Fill in all fields and click save to add a new lead"}
                  </p>
                </Title>
              }
              style={{
                margin: "auto",
                maxWidth: "40em",
                textAlign: "center",
                marginTop: "3em",
                backgroundColor: "rgb(66, 148, 211)",
                borderRadius: "0.8em",
                boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              <div
                style={{
                  // padding: '3em',
                  // paddingTop: '1em',
                  // paddingBottom: '4em',
                  margin: "auto",
                }}
              >
                <StockForm
                  descriptions={descriptions}
                  editStock={this.state.editStock}
                  wrappedComponentRef={form => (this.vehiclesForm = form)}
                />
                <Tooltip placement="left" title="Save Lead">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.handleAddStock}
                    size="large"
                    style={{
                      position: "fixed",
                      bottom: "6.5em",
                      right: "1em",
                      fontSize: "14px",
                      width: "4em",
                      height: "4em",
                      //height: "3em",
                      marginLeft: "0.7em",
                    }}
                  >
                    <CheckOutlined />
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title="Cancel">
                  <Button
                    shape="round"
                    onClick={e => this.setState({ previewVisible: false })}
                    style={{
                      fontSize: "14px",
                      width: "4em",
                      height: "4em",
                      position: "fixed",
                      bottom: "2em",
                      right: "1em",
                      border: "1px solid #EEEFF0",
                      backgroundColor: "lightgrey",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </Tooltip>
              </div>
            </Card>
          ) : (
            <Row>
              <Col span={24}>
                <StyledTabs
                  style={{ marginTop: "1em" }}
                  defaultActiveKey="1"
                  onChange={this.changeTypeTab}
                  tabBarExtraContent={{
                    // left: (
                    //   <Button
                    //     type="primary"
                    //     shape="round"
                    //     onClick={this.onAddStock}
                    //     icon={
                    //       <PlusCircleOutlined style={{ fontSize: '110%' }} />
                    //     }
                    //     size="large"
                    //     style={{
                    //       marginRight: '2.5em',
                    //       height: '3em',
                    //       width: '10em'
                    //     }}
                    //   >
                    //     Add Webinar
                    //   </Button>
                    // ),

                    right: (
                      <h1 style={{ color: "#656565", fontSize: 22 }}>
                        Transformation Program Clients
                        <span style={{ color: "lightgrey", fontSize: 25 }}>
                          : {this.props.stock.filter(stock => stock.condition === "TRANSFORMATION PROGRAM").length}
                        </span>
                      </h1>
                    ),
                  }}
                >
                  <TabPane tab="All" key="All">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles.slice().reverse().filter(record => record.condition === "TRANSFORMATION PROGRAM")}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 55,
                      }}
                      style={{
                        minWidth: "15em",
                        marginTop: "0.5em",
                      }}
                    // onRow={(record, rowIndex) => {
                    //   if (record.condition === "TRANSFORMATION PROGRAM PREV") {
                    //     return null;
                    //   } else {
                    //     return {
                    //       onClick: e => {
                    //         e.stopPropagation();
                    //         this.handleEditStock(record);
                    //       },
                    //     };
                    //   }
                    // }}
                    >
                      <Column
                        {...this.getColumnSearchProps("eventTitle")}
                        title="Name & Surname"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />
                      <Column
                        {...this.getColumnSearchProps("leadEmail")}
                        title="Email"
                        dataIndex="leadEmail"
                        key="leadEmail"
                        render={(text, stock) => (
                          <a href={"mailto:" + stock.leadEmail}>
                            {stock.leadEmail}
                          </a>
                        )}
                      />
                      <Column
                        {...this.getColumnSearchProps("contactNumber")}
                        title="Phone"
                        dataIndex="contactNumber"
                        key="contactNumber"
                        render={(text, stock) => (
                          <a
                            href={
                              "https://wa.me/27" +
                              stock.contactNumber.substring(1)
                            }
                            target="_blank"
                          >
                            {stock.contactNumber}
                          </a>
                        )}
                      />
                      <Column
                        {...this.getColumnSearchProps("condition")}
                        title="Workout Plan"
                        dataIndex="condition"
                        //key: "condition",
                        render={condition => {
                          switch (condition) {
                            case "CONTACT LEAD":
                              return this.renderCondition(
                                "CONTACT LEAD",
                                "#4BED5B"
                              );
                            case "TRANSFORMATION PROGRAM":
                              return this.renderCondition(
                                "TRANSFORMATION PROGRAM",
                                "lightgrey"
                              );
                            case "12 WEEK PLAN":
                              return this.renderCondition(
                                "12 WEEK PLAN",
                                "blue"
                              );
                            case "PREMIUM PLAN":
                              return this.renderCondition(
                                "PREMIUM PLAN",
                                "blue"
                              );
                            default:
                              return this.renderCondition("None", "lightgrey");
                          }
                        }}
                      />
                      <Column
                        {...this.getColumnSearchProps("paid")}
                        title="Paid"
                        dataIndex="paid"
                        key="paid"
                        render={(text, stock) => (
                          <span>
                            {stock.paid === "Yes" ? (
                              <StyledBadge color="rgb(66, 148, 211)" />
                            ) : (
                              <StyledBadge color="lightgrey" />
                            )}
                          </span>
                        )}
                      />
                      <Column
                        {...this.getColumnSearchProps("timeDate")}
                        title="Date & Time"
                        dataIndex="timeDate"
                        key="timeDate"
                      // render={text => this.formatDate(text)}
                      />
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(record, stock) => (
                          record.condition === "TRANSFORMATION PROGRAM PREV" ? null : (
                            <span>
                              <EditFilled
                                style={{
                                  backgroundColor: "rgb(66, 148, 211)",
                                  color: "white",
                                  padding: "0.5em",
                                  borderRadius: "3em",
                                }}
                                data={stock._id}
                                onClick={e => this.handleEditStock(stock)}
                              />
                              <Divider type="vertical" />

                              <DeleteFilled
                                style={{
                                  backgroundColor: "#C94F4F",
                                  color: "white",
                                  padding: "0.5em",
                                  borderRadius: "3em",
                                }}
                                data={stock._id}
                                onClick={this.handleDeleteStock}
                              />

                            </span>)
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  {/* <TabPane tab="CONTACT LEAD" key="CONTACT LEAD">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Name & Surname"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />
                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />

                      <Column
                        {...this.getColumnSearchProps('condition')}
                        title="Category"
                        dataIndex="condition"
                        //key: "condition",
                        render={condition => {
                          switch (condition) {
                            case 'CONTACT LEAD':
                              return this.renderCondition(
                                'CONTACT LEAD',
                                '#4BED5B'
                              );
                            case 'TRANSFORMATION PROGRAM':
                              return this.renderCondition('TRANSFORMATION PROGRAM', 'lightgrey');
                            case '12 WEEK PLAN':
                              return this.renderCondition('12 WEEK PLAN', 'blue');
                            case 'PREMIUM PLAN':
                              return this.renderCondition('PREMIUM PLAN', 'blue');
                            default:
                              return this.renderCondition('None', 'lightgrey');
                          }
                        }}
                      />
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              Edit
                            </Button>
                            <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            />
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  <TabPane tab="TRANSFORMATION PROGRAM" key="TRANSFORMATION PROGRAM">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                      onChange={this.handleSort}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Name & Surname"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />
                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />

                      <Column
                        {...this.getColumnSearchProps('condition')}
                        title="Category"
                        dataIndex="condition"
                        //key: "condition",
                        render={condition => {
                          switch (condition) {
                            case 'CONTACT LEAD':
                              return this.renderCondition(
                                'CONTACT LEAD',
                                '#4BED5B'
                              );
                            case 'TRANSFORMATION PROGRAM':
                              return this.renderCondition('TRANSFORMATION PROGRAM', 'lightgrey');
                            case '12 WEEK PLAN':
                              return this.renderCondition('12 WEEK PLAN', 'blue');
                            case 'PREMIUM PLAN':
                              return this.renderCondition('PREMIUM PLAN', 'blue');
                            default:
                              return this.renderCondition('None', 'lightgrey');
                          }
                        }}
                      />
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              Edit
                            </Button>
                            <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            />
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  <TabPane tab="12 WEEK PLAN" key="12 WEEK PLAN">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Name & Surname"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />
                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />

                      <Column
                        {...this.getColumnSearchProps('condition')}
                        title="Category"
                        dataIndex="condition"
                        //key: "condition",
                        render={condition => {
                          switch (condition) {
                            case 'CONTACT LEAD':
                              return this.renderCondition(
                                'CONTACT LEAD',
                                '#4BED5B'
                              );
                            case 'TRANSFORMATION PROGRAM':
                              return this.renderCondition('TRANSFORMATION PROGRAM', 'lightgrey');
                            case '12 WEEK PLAN':
                              return this.renderCondition('12 WEEK PLAN', 'blue');
                            case 'PREMIUM PLAN':
                              return this.renderCondition('PREMIUM PLAN', 'blue');
                            default:
                              return this.renderCondition('None', 'lightgrey');
                          }
                        }}
                      />
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              Edit
                            </Button>
                            <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            />
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  <TabPane tab="PREMIUM PLAN" key="PREMIUM PLAN">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Name & Surname"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />
                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />

                      <Column
                        {...this.getColumnSearchProps('condition')}
                        title="Category"
                        dataIndex="condition"
                        //key: "condition",
                        render={condition => {
                          switch (condition) {
                            case 'CONTACT LEAD':
                              return this.renderCondition(
                                'CONTACT LEAD',
                                '#4BED5B'
                              );
                            case 'TRANSFORMATION PROGRAM':
                              return this.renderCondition('TRANSFORMATION PROGRAM', 'lightgrey');
                            case '12 WEEK PLAN':
                              return this.renderCondition('12 WEEK PLAN', 'blue');
                            case 'PREMIUM PLAN':
                              return this.renderCondition('PREMIUM PLAN', 'blue');
                            default:
                              return this.renderCondition('None', 'lightgrey');
                          }
                        }}
                      />
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              Edit
                            </Button>
                            <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            />
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane> */}
                  {/*
                  <TabPane tab="Demo" key="Demo">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      //columns={columns}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('vehicleStockNumber')}
                        title="Stock Number"
                        dataIndex="vehicleStockNumber"
                        key="vehicleStockNumber"
                      />
                      <Column
                        {...this.getColumnSearchProps('modelYear')}
                        title="Year"
                        dataIndex="modelYear"
                        key="modelYear"
                      />{' '}
                      <Column
                        {...this.getColumnSearchProps('make')}
                        title="Make"
                        dataIndex="make"
                        key="make"
                      />{' '}
                      <Column
                        {...this.getColumnSearchProps('series')}
                        title="Model"
                        dataIndex="series"
                        key="series"
                      />{' '}
                      <Column
                        {...this.getColumnSearchProps('modelDesc')}
                        title="Derivative"
                        dataIndex="modelDesc"
                        key="modelDesc"
                      />
                      <Column
                        {...this.getColumnSearchProps('condition')}
                        title="Condition"
                        dataIndex="condition"
                        //key: "condition",
                        render={condition => {
                          switch (condition) {
                            case 'New':
                              return this.renderCondition('New', '#4BED5B');
                            case 'Used':
                              return this.renderCondition('Used', '#EE4C4C');
                            case 'Demo':
                              return this.renderCondition('Demo', '#FF8F28');
                            default:
                              return this.renderCondition('None', 'lightgrey');
                          }
                        }}
                      />
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              Edit
                            </Button>
                            <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            />
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane> */}
                </StyledTabs>
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    );
  }
}

const WrappedStock = Form.create()(Stock);
export default WrappedStock;
